import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation, useHref } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  Layout,
  Menu,
  Breadcrumb,
  // theme,
  Avatar,
  Dropdown,
  Space,
  Divider,
  Badge,
  Popover,
  Spin,
} from "antd";
import type { MenuProps } from "antd";
import {
  UserOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  SwapOutlined,
  // HomeOutlined,
  BellOutlined,
  // ExclamationCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { cloneDeep } from "lodash-es";
import "./index.less";
import api from "@/api";
import {
  BASE_SET_MENU,
  BASE_SET_LEFT_MENU,
  BASE_SET_AUTORIZE,
  BASE_SET_SYSTEM,
  BASE_SET_NOTIFICATIONS,
  BASE_SET_USER_DETAIL,
  BASE_SET_NOTIFICATION_OPEN,
} from "@/stores/base";
import { BASE_SYSTEM_ENUM } from "@/utils/base";
import actions from "@/stores/globalstate";
import { swapNavMode, getNavMode } from "@/utils/modeControl";
import MenuWorkbench from "@/components/Menu";
import Noticel from "../Noticel";
import encodeQuery from "@/utils/encodeQuery";
import { getSubAppUrl } from "@/config/microApp";
// import { Modal } from '@/components/BaseUI';
import { loginUrl, goLogin } from "@/utils/base";
import LogoKaihong from "@/assets/images/logo_kaihong.png";
import { initRender } from "@/index";
import { getTopBdp, token } from "@/utils/bdp";
import { X_ACCESS_TOKEN, BDP_TOKEN, X_ACCESS_TOKEN_AVAILABLE, X_ACCESS_TOKEN_AVAILABLE_VALUE } from "@/utils/base";

const TWBasePrefixCls = "tw-base";
const { Header, Content, Sider } = Layout;

function Main(props: any) {
  const [collapsed, setCollapsed] = useState(false); // 左侧slider展开/收起状态
  const [openKeys, setOpenKeys] = useState<any[]>([]); // 左侧菜单展开的项
  const [topMenuSelectedKeys, setTopMenuSelectedKeys] = useState([]); // 头部导航选中的导航
  const [leftMenuSelectedKeys, setLeftMenuSelectedKeys] = useState([]); // 左侧菜单选中的菜单
  const [breadcrumbItems, setBreadcrumbItems] = useState([]); // 面包屑数据
  const [menuType, setMenuType] = useState(""); // 工作台/菜单模式状态
  const [headData, setHeadData] = useState<any>({}); // 头部
  const [isLogin, setIsLogin] = useState(false);
  const [isNetError, setIsNetError] = useState(false); // 判断是否接口异常访问

  // const { token: { colorBgContainer } } = theme.useToken();
  // const { modal } = App.useApp();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateSubApp = (url: string) => {
    if (location.hash.includes("/khlinks")) {
      actions.setGlobalState({ hashPath: url });
    } else {
      navigate(`/#/khlinks${url}`);
    }
  };

  /**
   * 登出
   */
  const handleClickLogout = async () => {
    await api.baseLogout();
    // 跳转路由到登录界面
    // localStorage.clear();
    localStorage.removeItem(X_ACCESS_TOKEN);
    localStorage.removeItem(BDP_TOKEN); // 清除bdp token
    localStorage.removeItem(X_ACCESS_TOKEN_AVAILABLE);
    localStorage.removeItem("USER_INFO");
    if (!window.location.href.includes(loginUrl)) {
      goLogin();
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a href="javascript: void(0)" onClick={() => navigateSubApp("/account/center")}>
          个人中心
        </a>
      ),
      icon: <UserOutlined />,
    },
    { type: "divider" },
    {
      key: "2",
      label: (
        <a href="javascript: void(0)" onClick={() => navigateSubApp("/helpCenter/guide")}>
          帮助中心
        </a>
      ),
      icon: <QuestionCircleOutlined />,
    },
    { type: "divider" },
    {
      key: "3",
      label: <div onClick={handleClickLogout}>退出登录</div>,
      icon: <LogoutOutlined />,
    },
  ];

  const onCollapse = (collapsed: boolean | ((prevState: boolean) => boolean)) => {
    setCollapsed(collapsed);
  };

  /**
   * 获取用户菜单
   */
  const getBaseUserOwnTree = async () => {
    try {
      const params = {
        paging: false,
        terms: [
          {
            terms: [
              {
                terms: [
                  {
                    column: "owner",
                    termType: "eq",
                    value: "iot",
                  },
                  {
                    column: "owner",
                    termType: "isnull",
                    value: "1",
                    type: "or",
                  },
                ],
              },
            ],
          },
        ],
      };
      const res = await api.baseUserOwnTree(params);
      const { status, result } = res;
      if (status === 200) {
        // 成功
        setIsLogin(true);
        initRender();
        props.setMenu(result);
        // props.base.menus
      } else {
        props.setMenu([]);
      }
    } catch(error) {
      setIsNetError(true);
      console.error(error);
    }
  };

  /**
   * 获取用户基本信息
   */
  const getBaseAutorizeMe = async () => {
    const res = await api.baseAutorizeMe();
    const { status, result } = res;
    if (status === 200) {
      // 成功
      props.setAutorize(result);
    } else {
      props.setAutorize(null);
    }
  };

  /**
   * 获取用户基本信息
   */
  const getUserDetail = async () => {
    const res = await api.baseUserDetail();
    const { status, result } = res;
    if (status === 200) {
      // 成功
      props.setUserDetail(result);
    } else {
      props.setUserDetail({});
    }
  };

  /**
   * 获取系统基本信息
   */
  const getBaseSystem = async () => {
    const res = await api.baseSystemConfig();
    const { status, result } = res;
    if (status === 200) {
      // 成功
      props.setSystem(result);
      // setHeadData(result)
    } else {
      props.setSystem({});
      // setHeadData({})
    }
  };

  /**
   * 格式化菜单数据，将id->key, name->label
   * @param menusData 菜单数据
   * @returns 格式化好后的菜单数据
   */
  const formatMenusData = (menusData: any = []) => {
    return menusData.map((menu: any) => {
      const menuItem = cloneDeep(menu);
      const result: any = {};
      result.key = `${menuItem.id}_${menuItem.url}`;
      result.label = menuItem.name;
      result.icon = menuItem.icon ? <i style={{ fontSize: 16 }} className={`icon iconfont ${menuItem.icon}`} /> : <></>;
      result.title = menuItem.name;
      // 给子菜单添加onTitleMouseEnter事件，用于显示title
      result.onTitleMouseEnter = (mouseEnterParam: any) => {
        const target = mouseEnterParam?.domEvent?.target as HTMLElement;
        if (target && !target.title) {
          target.title = menuItem.name;
        }
      };
      if (menuItem?.children?.length) {
        result.children = formatMenusData(menuItem.children);
      }
      return result;
    });
  };

  /**
   * 格式化菜单数据->默认展开第一个数据
   * @param menusData 菜单数据
   * @returns 返回默认展开第一个的数据
   */
  const formatDefaultOpenKeys = (menusData: any = []) => {
    const result = menusData.filter((_menu: any, index: number) => index === 0);
    return result.map((item: { id: string; url: string }) => {
      return `${item.id}_${item.url}`;
    });
  };

  /**
   * 初始化页面时，当前路由匹配出菜单
   * @param menus 菜单列表
   * @returns 当前路由匹配到的菜单
   */
  const initMenuSelectedKeys: any = (menus: any) => {
    const result: string[] = [];
    const fn = (menus: any) => {
      return menus.filter((menu: { url: string; id: any; children: string | any[] }) => {
        // route mode: history | hash
        const wholeUrl =
          (BASE_SYSTEM_ENUM.APP_NAME && !BASE_SYSTEM_ENUM.APP_NAME.startsWith("/") ? "/" : "") +
          BASE_SYSTEM_ENUM.APP_NAME +
          menu.url;
        const locationHash = location.hash.split('?').shift();
        if (
          (location.pathname && location.pathname.includes(wholeUrl)) ||
          (locationHash && locationHash.endsWith(menu.url))
        ) {
          result.push(`${menu.id}_${menu.url}`);
          return true;
        } else {
          if (menu?.children?.length) {
            const keys = fn(menu.children);
            if (keys?.length) {
              return true;
            }
            return false;
          }
          return false;
        }
      });
    };

    return {
      keys: fn(menus),
      selectedKeys: result,
    };
  };

  /**
   * 选中当前的左侧菜单项，默认选中第一个
   * @param menus 菜单
   * @returns 选中的菜单key
   */
  const selectedCurrentKey = (menus: any = []) => {
    const result: any = [];
    const fn = (menus: any) => {
      for (let i = 0; i < menus.length; i++) {
        if (i === 0) {
          const menu = menus[i];
          if (menu?.children?.length) {
            fn(menu.children);
          } else {
            result.push(`${menu.id}_${menu.url}`);
            const truthUrl = getSubAppUrl(menu.url);
            if (truthUrl) {
              navigate(truthUrl);
            }
            break;
          }
        }
      }
    };
    fn(menus);
    return result;
  };

  /**
   * 点击头部菜单事件
   * @param e 当前点击头部菜单的对象
   */
  const handleClickMenu = (e: { key: string }, selectedKey: boolean = true) => {
    if (e.key) {
      let urlType = "";
      let urlTarget = "";
      let url = "";
      for (const menu of props.base.menus) {
        if (menu.id === e.key) {
          urlType = menu.urlType;
          urlTarget = menu.urlTarget;
          url = menu.url;
        }
      }
      if (urlType === "outside" && urlTarget === "_blank" && url) {
        if (!url.startsWith("http")) {
          url = window.location.origin + (url.startsWith("/") ? url : `/${url}`);
        }
        // 点击 数据平台 跳转时，url中携带token（天津港南港项目）
        if (e.key === "cd3fe2b959d2d4b3099c1647bae3648d") {
          const token = localStorage.getItem(X_ACCESS_TOKEN);
          const key = CryptoJS.enc.Utf8.parse("1389624045696027");
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(token), key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          });
          url = url + `?XAT=${encodeURIComponent(encryptedData.toString())}`;
        }
        window.open(
          url,
          urlTarget,
          "toolbar=yes, menubar=yes, scrollbars=yes, resizable=yes, location=yes, status=yes"
        );
        return;
      }
    }
    const result: any = [];
    e.key && result.push(e.key);
    const currentMenu = props.base.menus.filter((menu: { id: string }) => menu.id === e.key);
    setTopMenuSelectedKeys(result);
    currentMenu.length && setOpenKeys(formatDefaultOpenKeys(currentMenu[0]?.children));
    currentMenu.length && props.setLeftMenu(currentMenu[0]);
    currentMenu.length && selectedKey && setLeftMenuSelectedKeys(selectedCurrentKey(currentMenu));
    // actions.setGlobalState({
    //   breadcrumbItems: []
    // });
  };

  /**
   * 点击左侧菜单进行路由跳转
   * @param e 点击左侧菜单的数据
   */
  const handleClickLeftMenu = (e: any) => {
    const result: any = [];
    e.key && result.push(e.key);
    setLeftMenuSelectedKeys(result);
    const url = e.key.slice(e.key.indexOf("_") + 1, e.key.length);
    const truthUrl = getSubAppUrl(url);
    if (truthUrl) {
      navigate(truthUrl);
    }
    // actions.setGlobalState({
    //   breadcrumbItems: []
    // });
  };

  /**
   * 点击切换菜单列表/工作台模式
   */
  const handleClickChangeModel = () => {
    // MENU_SHOW_MODE  card：工作台模式   menu：菜单模式
    // modal.confirm({
    //   title: '确认提示',
    //   icon: <ExclamationCircleOutlined />,
    //   content: `确定切换成${menuType === 'menu' ? '卡片' : '列表'}模式吗？`,
    //   okText: '确认',
    //   cancelText: '取消',
    //   onOk: function(close) {
    //     close();
    //     swapNavMode();
    //     setMenuType(getNavMode());
    //   },
    //   onCancel: function(close) {
    //     close();
    //   }
    // });
    swapNavMode();
    setMenuType(getNavMode());
  };

  /**
   * 返回首页
   */
  // const handleClickGoHome = () => {
  //   navigateSubApp('/home/index');
  // };

  /**
   * 加载消息条数
   */
  const initNoticelData = async () => {
    const res = await api.baseNotices(
      encodeQuery({
        terms: { state: "unread" },
        sorts: { notifyTime: "desc" },
      })
    );

    if (res.status === 200) {
      props.setNotifications(res.result);
    }
  };
  const [cardUpdateCode, setCardUpdateCode] = useState("");

  /**
   * 初始化
   */
  const initMain = () => {
    //fix：解决刷新主题颜色问题，登录成功后，保存当前主题颜色,改变主题颜色需要退出重新登录
    setHeadData({ headerTheme: localStorage.getItem("SYSTEM_THEME") || "dark" });
    getBaseUserOwnTree();
    getBaseAutorizeMe();
    getUserDetail();
    getBaseSystem();
    actions.onGlobalStateChange((state, prev) => {
      // 设置面包屑
      setBreadcrumbItems(state.breadcrumbItems);
      const { cardUpdate, notificationUpdate } = state;
      const { cardUpdate: cardUpdatePrev, notificationUpdate: notificationUpdatePrev } = prev;
      // 处理卡片更新事件
      if (
        cardUpdate.timestamp &&
        (!cardUpdatePrev.timestamp ||
          cardUpdate.timestamp > cardUpdatePrev.timestamp)
      ) {
        setCardUpdateCode(cardUpdate.code + cardUpdate.timestamp);
      }
      // 处理告警更新事件
      if (
        notificationUpdate.timestamp &&
        (!notificationUpdatePrev.timestamp ||
          notificationUpdate.timestamp > notificationUpdatePrev.timestamp)
      ) {
        initNoticelData();
      }
    });
    setMenuType(getNavMode());
    initNoticelData();
  };

  useEffect(() => {
    const availableToken = localStorage.getItem(X_ACCESS_TOKEN_AVAILABLE);
    const bdpToken = localStorage.getItem(BDP_TOKEN);
    // 如果当前token可用，则不再执行下面的逻辑
    if (availableToken === X_ACCESS_TOKEN_AVAILABLE_VALUE && (bdpToken && token && bdpToken?.includes(token))) {
      // 初始化页面时，当前路由匹配出导航和菜单及选中状态
      initMain();
      return;
    }
    // 0. 作为第三方平台子应用，第一步获取到平台的token
    getTopBdp(true).then(res => {
      const { status, result } = res;
      if (status === 200) {
        localStorage.setItem(X_ACCESS_TOKEN, result?.[X_ACCESS_TOKEN]);
        localStorage.setItem(BDP_TOKEN, result?.[BDP_TOKEN]);
        localStorage.setItem(X_ACCESS_TOKEN_AVAILABLE, X_ACCESS_TOKEN_AVAILABLE_VALUE);
        // 初始化页面时，当前路由匹配出导航和菜单及选中状态
        initMain();
      } else {
        console.error("获取token失败，请重新登录");
      }
    });
    // 初始化页面时，当前路由匹配出导航和菜单及选中状态
    // initMain();
  }, []);

  const findOpenKeys = (menus: any[], selectedKeys: string, result: any[]) => {
    for (const menu of menus) {
      if (selectedKeys.includes(`_${menu.url}`) && selectedKeys !== `${menu.id}_${menu.url}`) {
        result.push(`${menu.id}_${menu.url}`);
        if (menu?.children) {
          findOpenKeys(menu.children, selectedKeys, result);
        }
      }
    }
  }

  // 初始化页面时，当前路由匹配出导航和菜单及选中状态
  useEffect(() => {
    if (props?.base?.menus?.length === 0) {
      return;
    }
    const { keys, selectedKeys } = initMenuSelectedKeys(props.base.menus);
    
    if (keys?.length) {
      if (selectedKeys?.length) {
        const openKeys: any[] = [];
        findOpenKeys(keys, selectedKeys[0], openKeys);
        setOpenKeys(openKeys);
        setLeftMenuSelectedKeys(selectedKeys);
      }
    } else if (localStorage.getItem("INIT_SYSTEM") !== "1") {
      const defaultKeys = [props.base.menus[0]];
      const keyNode = defaultKeys[0];
      const defaultSelectedKeys: any = [`${keyNode.id}_${keyNode.url}`];
      if (defaultSelectedKeys?.length) {
        const openKeys: any[] = [];
        findOpenKeys(defaultKeys, defaultSelectedKeys[0], openKeys);
        setOpenKeys(openKeys);
        setLeftMenuSelectedKeys(defaultSelectedKeys);
      }
    }
  }, [props.base.menus]);

  /**
   * 根据菜单类型分别渲染
   * @returns menu
   */
  const siderMenuRender = () => {
    switch (menuType) {
      case "menu": // 菜单模式
        return (
          <Menu
            theme={headData?.headerTheme}
            mode="inline"
            openKeys={openKeys}
            selectedKeys={leftMenuSelectedKeys}
            // items={formatMenusData(props?.base?.leftMenus?.children)}
            items={formatMenusData(props?.base?.menus)}
            style={{ height: "100%", borderRight: 0, overflowY: 'auto' }}
            onClick={handleClickLeftMenu}
            onOpenChange={(keys: any) => {
              console.log('onOpenChange keys: ', keys);
              setOpenKeys(keys);
            }}
          />
        );
      case "card": // 卡片模式
        return (
          <MenuWorkbench
            theme={headData?.headerTheme}
            menuData={props?.base?.leftMenus?.children}
            onEntryClick={() => onCollapse(!collapsed)}
            cardUpdateCode={cardUpdateCode}
          />
        );
      default:
        console.warn(`未知的菜单类型：${menuType}`);
    }
  };

  // 左侧导航的classname
  const siderClassName = `${TWBasePrefixCls}-sider ${TWBasePrefixCls}-sider_${menuType}`;

  /**
   * 显示切换工作台的按钮图标
   * 管理员才会显示。目前后端的方案只有通过固定的user id来判断
   * 1199596756811550720 代表为管理员
   * @returns dom
   */
  const headerRightChangeModelRender = () => {
    return props?.base?.autorize?.user?.id === "1199596756811550720" ? (
      <SwapOutlined
        className={`${TWBasePrefixCls}-header-right_btn`}
        onClick={handleClickChangeModel}
      />
    ) : (
      <></>
    );
  };

  /**
   * 面包屑
   * @returns dom
   */
  const breadcrumbItemRender = () => {
    return breadcrumbItems.map((item: { title: string; path: string }, index) => {
      // 包含path的走此分支
      if (item.path) {
        return (
          <Breadcrumb.Item key={index}>
            <a
              href="javascript: void(0)"
              onClick={() => {
                const truthUrl = getSubAppUrl(item.path);
                if (truthUrl) {
                  navigate(truthUrl);
                }
              }}
            >
              {item.title}
            </a>
          </Breadcrumb.Item>
        );
      }
      return <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>;
    });
  };

  const breadcrumbRender = () => {
    return breadcrumbItems.length ? (
      <Breadcrumb style={{ margin: "8px 0", padding: "0 48px", lineHeight: "20px" }}>
        {breadcrumbItemRender()}
      </Breadcrumb>
    ) : (
      <></>
    );
  };

  return (
    <>
      {isLogin ? (
        <div className={`${headData?.headerTheme ? "theme-"+ headData?.headerTheme : "theme-dark"}`}>
          <Layout style={{ height: "100%", flexDirection: "column" }}>
            {/* <Header
              className={`${TWBasePrefixCls}-header`}
            >
              <div className={`${TWBasePrefixCls}-logo`}>
                <img src={props.base?.system?.logo || LogoKaihong} />
                <h1>
                  {props.base?.system?.title || BASE_SYSTEM_ENUM.SYSTEM_NAME}
                </h1>
              </div>
              <div className={`${TWBasePrefixCls}-header-menu`}>
                <Menu
                  theme={headData?.headerTheme === 'light'? 'light' : 'dark'}
                  mode="horizontal"
                  onClick={handleClickMenu}
                  selectedKeys={topMenuSelectedKeys}
                  items={props.base.menus.map((item: any) => {
                    return {
                      key: item.id,
                      label: item.name,
                      icon: item.icon ? <i style={{ fontSize: 16 }} className={`icon iconfont ${item.icon}`} /> : <></>,
                    };
                  })}
                />
              </div>
              <div className={`${TWBasePrefixCls}-header-right`}>
                <Space size={20}>
                  {headerRightChangeModelRender()}
                  <Popover
                    trigger="click"
                    placement="bottomRight"
                    overlayInnerStyle={{ padding: 0 }}
                    content={<Noticel />}
                    open={props.base.notificationOpen}
                    onOpenChange={(newOpen: boolean) => {
                      props.setNotificationsOpen(newOpen);
                    }}
                  >
                    <div>
                      <Badge size="small" count={props.base.notifications?.total || 0}>
                        <BellOutlined
                          className={`${TWBasePrefixCls}-header-right_btn`}
                        />
                      </Badge>
                    </div>
                  </Popover>

                  <Dropdown menu={{ items }} placement="bottomLeft" overlayStyle={{ minWidth: 120 }}>
                    <div className={`${TWBasePrefixCls}-header-right_avatar`}>
                      {props.base?.userDetail?.avatar ? (
                        <Avatar src={<img src={props.base?.userDetail?.avatar} alt="avatar" />} />
                      ) : (
                        <Avatar
                          size="small"
                          icon={<UserOutlined />}
                          style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                        />
                      )}
                      <span className={`${TWBasePrefixCls}-header-right_avatar_name`}>
                        {props.base.autorize?.user?.name || ""}
                      </span>
                    </div>
                  </Dropdown>
                </Space>
              </div>
            </Header> */}
            <Layout className={`${TWBasePrefixCls}-wrap`}>
              <Sider
                className={siderClassName}
                trigger={
                  menuType === "card" ? null : collapsed ? (
                    <div>
                      <Divider style={{ margin: 0 }} />
                      <MenuUnfoldOutlined />
                    </div>
                  ) : (
                    <div>
                      <Divider style={{ margin: 0 }} />
                      <MenuFoldOutlined />
                    </div>
                  )
                }
                collapsible
                collapsed={collapsed}
                collapsedWidth={menuType === "card" ? 0 : 50}
                width={menuType === "card" ? 420 : 240}
                theme={headData?.headerTheme}
                onCollapse={onCollapse}
              >
                <div style={{ height: "100%" }}>{siderMenuRender()}</div>
              </Sider>
              <Layout className={`${TWBasePrefixCls}-wrap_right`}>
                <Spin spinning={props.base.BASE_MAIN_ROOT_ISLOADING} delay={500}>
                  {/* 面包屑 */}
                  {breadcrumbRender()}

                  {/* 子应用容器 */}
                  <Content
                    id="main-container"
                    className={`${TWBasePrefixCls}-app-container`}
                    style={{
                      padding: 0,
                      margin: 0,
                      minHeight: 450,
                    }}
                  />
                </Spin>
              </Layout>
            </Layout>
          </Layout>
        </div>
      ) : (
        isNetError ? (
          <div>超级设备管理平台服务器异常，请联系管理员！</div>
        ): (<Spin size="large" />)
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return state;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // 设置菜单数据
    setMenu: (data: any) => {
      dispatch({
        type: BASE_SET_MENU,
        data,
      });
    },
    // 设置左侧菜单
    setLeftMenu: (data: any) => {
      dispatch({
        type: BASE_SET_LEFT_MENU,
        data,
      });
    },
    // 设置用户基本信息
    setAutorize: (data: any) => {
      dispatch({
        type: BASE_SET_AUTORIZE,
        data,
      });
    },
    setUserDetail: (data: any) => {
      dispatch({
        type: BASE_SET_USER_DETAIL,
        data,
      });
    },
    // 设置系统基本信息
    setSystem: (data: any) => {
      dispatch({
        type: BASE_SET_SYSTEM,
        data,
      });
    },
    // 设置系统基本信息
    setNotifications: (data: any) => {
      dispatch({
        type: BASE_SET_NOTIFICATIONS,
        data,
      });
    },
    // 设置告警消息弹窗状态
    setNotificationsOpen: (data: any) => {
      dispatch({
        type: BASE_SET_NOTIFICATION_OPEN,
        data,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
