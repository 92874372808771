/**
 * 燃鸿BDP平台交互相关
 */
import http from "@/api/http";
import { X_ACCESS_TOKEN, BASE_SYSTEM_ENUM } from "@/utils/base";

// const BDP_AUTH = "bdp-auth";
// const BDP_SYSTEM = "bdp-system";

// 先拿到token
export const token = location.hash.split("token=").pop()?.split("&")?.shift();
export const BDP_HOST = (window as any)?.KH_BASE_CONFIG?.BDP_HOST;
export const APP_MODE = (window as any)?.KH_BASE_CONFIG?.APP_MODE;

console.log("bdp token: ", token);
console.log("BDP_HOST: ", BDP_HOST);
console.log("APP_MODE: ", APP_MODE);
console.log("bdp location: ", location);

export const getTopBdp = (syncFlag = false, tokenBdp = "") => {
  let tokenIn = tokenBdp || token || "";
  // 本地调试使用
  if (!tokenIn || tokenIn.includes('#')) {
    tokenIn = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRfaWQiOiIwMDAwMDAiLCJzZWNyZXRLZXkiOiJNRTJXTU5ER0hBM1RNWlJSR0pRVElNREVHRTRESVlaVE1FWUdLT0pUTVFaVEVZUlNNUVpBPT09PSIsInVzZXJfbmFtZSI6ImRldmljZV90ZXN0IiwicmVhbF9uYW1lIjoi5byA6bi_IiwiYXZhdGFyIjoiIiwiYXV0aG9yaXRpZXMiOlsi54mp6IGU5o6l5YWl566h6Lev5ZGYIl0sImNsaWVudF9pZCI6InNhYmVyIiwicm9sZV9uYW1lIjoi54mp6IGU5o6l5YWl566h6Lev5ZGYIiwibGljZW5zZSI6InBvd2VyZWQgYnkgdG9wYmRwIiwid2Vha1Bhc3N3b3JkIjpmYWxzZSwicG9zdF9pZCI6IiIsInVzZXJfaWQiOiIxODQ4MTgyMTAxNjQ2MDQ5MjgyIiwicm9sZV9pZCI6IjE4NDgxODE0ODIwNzE4NTEwMTAiLCJzY29wZSI6WyJhbGwiXSwibmlja19uYW1lIjoi6LaF57qn6K6-5aSH5rWL6K-VIiwib2F1dGhfaWQiOiIiLCJkZXRhaWwiOnsidHlwZSI6IndlYiJ9LCJleHAiOjE3Mjk2NTI0OTgsImRlcHRfaWQiOiIxNzc3OTQ0ODIxNTg2MjU1ODczIiwianRpIjoiOTZlZTI5OWQtYmYwMi00MzY5LWJkMmEtZmRhYmZlMGE4NTRlIiwiYWNjb3VudCI6ImRldmljZV90ZXN0In0.nzgLHu_i38mVJHi-YQv9pBpnno7wc7RWN1AYmN_uhbY";
  }
  return http.post(
    `/${BASE_SYSTEM_ENUM.API_BASE}/user/detail/topBdp/exist`,
    {
      token: tokenIn,
      syncFlag,
    },
    { headers: { [X_ACCESS_TOKEN]: "adminFixedToken" } }
  );
};

// const parentHost = parent.location.host;
// console.log('bdp parent.location.host: ', parentHost);
// const parentHost = (window as any)?.KH_BASE_CONFIG?.BDP_HOST;
// const parentHost = "";

// console.log("bdp parentHost: ", parentHost);

/**
 * 单点登录接口
 * 第三方系统根据获取的token信息向TOP-BDP平台发起登录验证请求，获取登录用户信息
 * @param params
 * @param config
 * @returns
 * 返回的data为null或调用其他接口返回code为403就表示token过期
 */
// const oauthVerify = (tokenIn: string) =>
//   http.post(
//     `${parentHost}/${BDP_AUTH}/oauth/verify`,
//     {},
//     {
//       headers: {
//         token: tokenIn,
//       },
//     }
//   );
/**
 * 2.3.2获取应用菜单接口
 * @param token
 * @param applicationId
 * @returns
 */
// const getMenu = (token: string, applicationId: string) =>
//   http.get(`${parentHost}/${BDP_SYSTEM}/menu/routesByApplication`, {
//     params: {
//       "Bdp-Auth": "bearer " + token,
//       applicationId,
//     },
//   });

/**
 * 2.3.3获取菜单按钮接口
 * @param token
 * @returns
 */
// const getMenuButtons = (token: string, applicationId: string) =>
//   http.get(`${parentHost}/${BDP_SYSTEM}/menu/buttons`, {
//     params: {
//       "Bdp-Auth": "bearer " + token,
//       applicationId,
//     },
//   });

// oauthVerify(token).then((res) => {
//   console.log("oauthVerify res: ", res);
//   const { data, code } = res;
//   if (code === 403 || !data) {
//     console.log("token过期");
//     // TODO: 重定向到首页 /home/index
//   }
// });

// getMenu(token, "10").then((res) => {
//   console.log("getMenu res: ", res);
// });

// getMenuButtons(token, "10").then((res) => {
//   console.log("getMenuButtons res: ", res);
// });
